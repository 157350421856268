import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

const lngs = {
  en: { nativeName: "English" },
  fr: { nativeName: "French" },
};
const LanguageSwitch = () => {
  const [userLanguage, setLanguage] = useState("en");

  const { t, i18n } = useTranslation();
  const handleChange = (event: React.MouseEvent<HTMLElement>, lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={userLanguage}
        onChange={handleChange}
        aria-label="language"
        sx={{ maxHeight: "18px", padding: "3px", margin: "2px 5px 15px" }}
      >
        <ToggleButton value="en">EN</ToggleButton>
        <ToggleButton value="fr">FR</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
export default LanguageSwitch;
