import {
  AppBar,
  CssBaseline,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../img/Artboard 1.png";
import { Email as EmailIcon, Call } from "@mui/icons-material";
import Navbar from "./navbar";

const LogoBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <AppBar position="static">
        <CssBaseline />
        <Toolbar>
          <Stack
            sx={{ marginX: "auto", display: "inline-flex" }}
            direction="column"
          >
            <Grid container sx={{ marginX: "auto" }}>
              <img src={logo} width="50px" alt="CHF Logo" />
              <Grid xs={12} md={6}>
                <Stack direction="column">
                  <Typography variant="h6" sx={{ color: "#010078" }}>
                    Caryss Health Foundation
                  </Typography>
                  <Typography variant="subtitle1">
                    Caring for Health, Nurturing Life
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: "2em", marginBottom: "-1em" }}>
              <Navbar />
            </Grid>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default LogoBar;
