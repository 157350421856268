import Header from "./components/header";
import Content from "./components/content";
import Footer from "./components/footer";
import "./App.css";
import { Suspense } from "react";
import "@aws-amplify/ui-react/styles.css";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Loading = () => {
  return <span>Loading ...</span>;
};

const WrappedApp = () => {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
};

export default WrappedApp;
