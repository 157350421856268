/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createNewsletterSubscription = /* GraphQL */ `mutation CreateNewsletterSubscription(
  $input: CreateNewsletterSubscriptionInput!
  $condition: ModelNewsletterSubscriptionConditionInput
) {
  createNewsletterSubscription(input: $input, condition: $condition) {
    id
    subscriberEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNewsletterSubscriptionMutationVariables,
  APITypes.CreateNewsletterSubscriptionMutation
>;
export const updateNewsletterSubscription = /* GraphQL */ `mutation UpdateNewsletterSubscription(
  $input: UpdateNewsletterSubscriptionInput!
  $condition: ModelNewsletterSubscriptionConditionInput
) {
  updateNewsletterSubscription(input: $input, condition: $condition) {
    id
    subscriberEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNewsletterSubscriptionMutationVariables,
  APITypes.UpdateNewsletterSubscriptionMutation
>;
export const deleteNewsletterSubscription = /* GraphQL */ `mutation DeleteNewsletterSubscription(
  $input: DeleteNewsletterSubscriptionInput!
  $condition: ModelNewsletterSubscriptionConditionInput
) {
  deleteNewsletterSubscription(input: $input, condition: $condition) {
    id
    subscriberEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNewsletterSubscriptionMutationVariables,
  APITypes.DeleteNewsletterSubscriptionMutation
>;
