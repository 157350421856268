import {
  Email as EmailIcon,
  Call as CallIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import { Container, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import SocialMediaLinks from "./socialmedialinks";

const ContactData = () => {
  return (
    <Grid
      sx={{
        backgroundColor: "#CCCCFF",
        textAlign: "left",
        padding: "0.5em",
      }}
    >
      <Typography>
        <span color="#66cc66">Caryss</span>
        <span color="#6666cc"> Health Foundation</span>
      </Typography>
      <Typography variant="body2" sx={{ textDecoration: "none" }}>
        <EmailIcon /> {t("contact.email")}:{" "}
        <a href="mailto:info@caryss-health.org">info@caryss-health.org</a>
      </Typography>
      <Typography variant="body2">
        <LocationIcon /> {t("contact.address")}: Yaounde
      </Typography>
      <Typography variant="body2">
        <CallIcon /> {t("contact.phone")}: (+237) 677-777-777
      </Typography>
      <SocialMediaLinks />
    </Grid>
  );
};
export default ContactData;
