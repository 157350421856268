import { Grid, Typography } from "@mui/material";
import LanguageSwitch from "./languageswitch";

const TopBar = () => {
  return (
    <>
      <Grid container sx={{ padding: "0.2em" }}>
        <Grid xs={12} md={5}>
          <Typography variant="body1">123 Street, New York, USA</Typography>
        </Grid>
        <Grid xs={12} md={5}>
          <Typography variant="body1">Mon-Sat 09am-5pm, Sun Closed</Typography>
        </Grid>
        <Grid xs={6} md={2}>
          <LanguageSwitch />
        </Grid>
      </Grid>
    </>
  );
};
export default TopBar;
