import {
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import usePaymentForm from "./usePaymentForm";
import { Button, Card, CardContent } from "@mui/material";

const CheckoutForm = () => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const { handleSubmit } = usePaymentForm();
  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <CardElement />
          <input type="number" />
          <Button variant="contained">Pay</Button>
        </form>
      </CardContent>
    </Card>
  );
};

const DonationApp = () => {
  const stripePromise = loadStripe("process.env.STRIPE_PK_KEY");
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};
export default DonationApp;
