import { useTranslation } from "react-i18next";
import {
  Drawer,
  Grid,
  List,
  ListItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import SocialMediaLinks from "./socialmedialinks";

const useStyles = makeStyles((theme: any) => ({
  logotext: { display: "block" },
  navlinks: {
    display: "flex",
    color: "blue",
    minWidth: "250px",
    marginLeft: "10px",
  },
  link: { textDecoration: "none" },
}));

const Navbar = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openDrawer, setOpenDrawer] = useState(false);

  const navList = [
    { linkText: t("navbar.Home"), link: "/" },
    { linkText: t("navbar.AboutUs"), link: "about" },
    { linkText: t("navbar.Services"), link: "services" },
    { linkText: t("navbar.ContactUs"), link: "contact" },
    { linkText: t("navbar.Donate"), link: "donate" },
  ];

  const handleOpenMenu = () => setOpenDrawer(!openDrawer);

  return (
    <>
      <Grid container>
        <Grid item>
          {isMobile ? (
            <>
              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                  {navList.map((listItem) => (
                    <ListItem
                      key={listItem.linkText}
                      onClick={() => setOpenDrawer(false)}
                    >
                      <Typography
                        component="a"
                        sx={{ textDecoration: "none" }}
                        href={listItem.link}
                      >
                        {listItem.linkText}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
              <Grid sx={{ display: "flex" }}>
                <Grid>
                  <Tooltip title="Open Menu">
                    <IconButton onClick={handleOpenMenu}>
                      <MenuIcon sx={{ color: "white", fontWeight: "bold" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className={classes.navlinks}>
              <Grid
                sx={{
                  backgroundColor: "white",
                  height: "3em",
                  display: "flex",
                }}
              >
                {navList.map((listItem) => (
                  <ListItem key={listItem.linkText}>
                    <Typography
                      component="a"
                      noWrap
                      href={listItem.link}
                      sx={{
                        display: "block",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      {listItem.linkText}
                    </Typography>
                  </ListItem>
                ))}
              </Grid>
            </div>
          )}
        </Grid>
        <Grid item>
          <SocialMediaLinks />
        </Grid>
      </Grid>
    </>
  );
};
export default Navbar;
