import { API } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import {
  Typography,
  Grid,
  Input,
  Button,
  CardContent,
  Card,
  Snackbar,
  Container,
} from "@mui/material";
import { createNewsletterSubscription } from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import SocialMediaLinks from "./socialmedialinks";

const NewsLetterForm = () => {
  const { t } = useTranslation();

  const visitorData = { email: "" };
  const updateVisitorData = (emailValue: string) => {
    visitorData["email"] = emailValue;
  };
  const getEmail = (event: any) => {
    event.preventDefault();
    updateVisitorData(event.target.value);
  };
  const createNewsletterSubs = async () => {
    try {
      await API.graphql({
        query: createNewsletterSubscription,
        variables: { input: { subscriberEmail: visitorData.email } },
      });
    } catch (error) {
      console.log(error);
    }
    updateVisitorData("");
  };

  return (
    <Grid
      container
      sx={{ maxWidth: "800px", textAlign: "center", marginX: "auto" }}
    >
      <Card>
        <CardContent>
          <Typography variant="body2">{t("newsletter.content")}</Typography>
          <Grid container>
            <Grid item>
              <Input
                id="visitorEmail"
                placeholder={t("newsletter.title")}
                onChange={getEmail}
              />
              <Button variant="contained" onClick={createNewsletterSubs}>
                {t("newsletter.action")}
              </Button>

              <Snackbar
                open={true}
                color="white"
                message={visitorData.email}
                autoHideDuration={5000}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default NewsLetterForm;
