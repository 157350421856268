import { Facebook, Twitter, LinkedIn, Instagram } from "@mui/icons-material";
import { Container, Stack, Link } from "@mui/material";
const SocialMediaLinks = () => {
  const links = {
    facebook: "https://www.facebook.com/caryss-health",
    twitter: "https://www.twitter.com/caryss-health",
    linkedIn: "https://www.linkedin.com/caryss-health",
    instagram: "https://www.instagram.com/caryss-health",
  };
  return (
    <Container
      maxWidth="md"
      sx={{
        color: "white",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Link href={links.facebook}>
          <Facebook />
        </Link>
        <Link href={links.twitter}>
          <Twitter />
        </Link>
        <Link href={links.linkedIn}>
          <LinkedIn />
        </Link>
        <Link href={links.instagram}>
          <Instagram />
        </Link>
      </Stack>
    </Container>
  );
};
export default SocialMediaLinks;
