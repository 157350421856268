import { Box, Container, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContactData from "./contactData";
import NewsLetterForm from "./newsform";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <Grid sx={{ maxWidth: "800px", padding: "2em", marginX: "auto" }}>
      <Container>
        <ContactData />
      </Container>
      <Container>
        <NewsLetterForm />
      </Container>
      <Box sx={{ float: "left" }}>&copy;2023 Caryss Health</Box>
      <Box sx={{ float: "right" }}>
        {t(`footer.designedBy`)}
        {"  "}
        <Link
          sx={{ textDecoration: "none" }}
          href="https://www.icitysystems.org"
        >
          iCitySystems
        </Link>
      </Box>
    </Grid>
  );
};
export default Footer;
