import { Card, CardContent, Grid, Slide, Typography } from "@mui/material";
import pic from "../img/Artboard3.png";
import { useTranslation } from "react-i18next";
import DonationApp from "./donationApp";

const PageLayout = ({ content }: any) => {
  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      {content.map((item: any) => {
        <Card>
          <CardContent>{item}</CardContent>
        </Card>;
      })}
    </Grid>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const founderStatements = [
    { content: t("founder.statements.1") },
    { content: t("founder.statements.2") },
    { content: t("founder.statements.3") },
    { content: t("founder.statements.4") },
    { content: t("founder.statements.5") },
    { content: t("founder.statements.6") },
  ];
  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      <Grid maxWidth="lg">
        <img width="100%" src={pic} alt="CaryssHealth Logo" />
      </Grid>
      <Grid>
        <Typography variant="h4">{t("founder.heading")}</Typography>
        <Typography variant="body1">
          <Card>
            <CardContent>
              {founderStatements.map((statement: any) => (
                <Slide direction="up" in={true} timeout={2000}>
                  <Typography variant="body1" sx={{ textAlign: "justify" }}>
                    {statement.content}
                  </Typography>
                </Slide>
              ))}
              <Typography variant="h6">
                {t("founder.founderName")} {t("founder.founderTitle")}
              </Typography>
              <Typography variant="h5">
                {t("founder.founderFunction")}
              </Typography>
            </CardContent>
          </Card>
        </Typography>
      </Grid>
    </Grid>
  );
};

const Donate = () => {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      <Slide direction="up" in={true} timeout={2000}>
        <Card>
          <CardContent>
            <Typography sx={{ textAlign: "left" }} variant="h6">
              {t("donate.title")}
            </Typography>
            <Typography sx={{ textAlign: "justify" }} variant="body1">
              {t("donate.message")}
            </Typography>
            <DonationApp />
          </CardContent>
        </Card>
      </Slide>
    </Grid>
  );
};

const About = () => {
  const { t } = useTranslation();
  const aboutItems = [
    {
      title: t("aboutUs.mission.title"),
      content: t("aboutUs.mission.content"),
    },
    {
      title: t("aboutUs.vision.title"),
      content: t("aboutUs.vision.content"),
    },
    {
      title: t("aboutUs.accomplishments.title"),
      content: t("aboutUs.accomplishments.content"),
    },
    {
      title: t("aboutUs.news.title"),
      content: t("aboutUs.news.content"),
    },
    {
      title: t("aboutUs.stories.title"),
      content: t("aboutUs.stories.content"),
    },
  ];

  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      <Typography variant="h4">{t("aboutUs.heading")}</Typography>
      {aboutItems.map((aboutItem) => (
        <Slide in={true} direction="up" timeout={2000}>
          <Card>
            <CardContent>
              <Grid xs={12} sx={{ textAlign: "justify" }}>
                <Typography variant="h6">{aboutItem.title}</Typography>
                <Typography sx={{ textAlign: "justify" }} variant="body1">
                  {aboutItem.content}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Slide>
      ))}
    </Grid>
  );
};

const Services = () => {
  const { t } = useTranslation();
  const servicesList = [
    {
      title: t("ourservices.1.title"),
      content: t("ourservices.1.content"),
    },
    {
      title: t("ourservices.2.title"),
      content: t("ourservices.2.content"),
    },
    {
      title: t("ourservices.3.title"),
      content: t("ourservices.3.content"),
    },
    {
      title: t("ourservices.4.title"),
      content: t("ourservices.4.content"),
    },
    {
      title: t("ourservices.5.title"),
      content: t("ourservices.5.content"),
    },
    {
      title: t("ourservices.6.title"),
      content: t("ourservices.6.content"),
    },
    {
      title: t("ourservices.7.title"),
      content: t("ourservices.7.content"),
    },
    {
      title: t("ourservices.8.title"),
      content: t("ourservices.8.content"),
    },
    {
      title: t("ourservices.9.title"),
      content: t("ourservices.9.content"),
    },
  ];
  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      <Typography variant="h4">{t("ourservices.heading")}</Typography>
      <Grid container spacing={1}>
        {servicesList.map((servicesItem, index) => (
          <Slide direction="up" in={true} timeout={2000 * index}>
            <Card sx={{ textAlign: "left", borderSpacing: "3em" }}>
              <CardContent>
                <Typography variant="h6">{servicesItem.title}</Typography>
                <Typography sx={{ textAlign: "justify" }} variant="body1">
                  {servicesItem.content}
                </Typography>
              </CardContent>
            </Card>
          </Slide>
        ))}
      </Grid>
    </Grid>
  );
};

const Contact = () => {
  const { t } = useTranslation();
  const contacts = [
    {
      title: "Cameroon",
      telephone: "(+237) 67*******",
      email: "info@caryss-health.org",
    },
  ];
  return (
    <Grid container sx={{ maxWidth: "800px", marginX: "auto" }}>
      <Typography variant="h4">{t("contact.heading")}</Typography>
      <Typography sx={{ textAlign: "justify" }} variant="body1">
        {t("contact.content")}
      </Typography>
      {contacts.map((contact) => (
        <Grid xs={12} md={6} lg={4} sx={{ textAlign: "justify" }}>
          <Typography variant="h6">{contact.title}</Typography>
          <Typography variant="body1">
            <span>Tel:</span>
            {contact.telephone}
          </Typography>
          <Typography variant="body1">E-mail: {contact.email}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export { Home, About, Services, Contact, Donate };
