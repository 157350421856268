import TopBar from "./topbar";
import LogoBar from "./logobar";
import { Grid } from "@mui/material";

const Header = () => {
  return (
    <Grid>
      <TopBar />
      <LogoBar />
    </Grid>
  );
};
export default Header;
